import React from "react";
import styled from "styled-components";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {SRLWrapper} from "simple-react-lightbox";
import Masonry from 'react-masonry-css';
import SEO from "../components/SEO";

const GalleryStyles = styled.div`
  .my-masonry-grid {
    display: flex;
    margin-left: -5px; /* gutter size offset */
    width: auto;
    padding: 3vw;
  }
  .my-masonry-grid_column {
    padding-left: 5px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column .gatsby-image-wrapper { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 5px;
  }
  
  .my-masonry-grid_column a {
    height: 100%;
  }
  
  .overlay {
    transition: all .5s ease;
  }

  .overlay:hover { /* change div to reference your elements you put in <Masonry> */
    filter: opacity(70%);
  }
  
  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }
`;

const lightBoxSettings = {
    settings: {
        overlayColor: "rgba(255, 255, 255, .9)",
    },
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false
    },
    caption: {
        showCaption: true,
        captionColor: "#000",
    },
    thumbnails: {
        showThumbnails: false
    }
};

const breakpointColumnsObj = {
    default: 4,
    992: 3,
    576: 2
};

export default function WorksPage({ data }){
    return (
        <>
            <SEO title={"Works"}/>
            <SRLWrapper options={lightBoxSettings}>
                <GalleryStyles>
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                        >
                            {data.works.nodes.map((item) => {
                                return (
                                    <div className="overlay">
                                        <a href={item.image.asset.url}>
                                            <GatsbyImage image={item.image.asset.gatsbyImageData} alt={item.caption}/>
                                        </a>
                                    </div>
                                )
                            })}
                    </Masonry>
                </GalleryStyles>
            </SRLWrapper>
        </>
    )
}


export const works = graphql`
    query WorksQuery {
        works: allSanityWorks {
            nodes {
                caption,
                image {
                    asset {
                        gatsbyImageData(placeholder: BLURRED)
                        url
                    }
                }
            }
        }
    }
`;
